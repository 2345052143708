import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePrevious } from 'react-use';
import io from 'socket.io-client';
import {
    HMSRoomState,
    selectAppData,
    selectIsConnectedToRoom,
    selectPeers,
    selectRoomState,
    useHMSStore,
} from '@100mslive/react-sdk';
import { Flex } from '@100mslive/roomkit-react';
import { Beam } from './Pages/Beam';
import { MainViewConnector } from '../layouts/mainViewConnector';
import { createAndUpdateScreenshots, fetchScreenShotImage, getQuestionNumber } from '../redux/action';
import FullPageProgress from './FullPageProgress';
import { generateUrl, isValidDataURI } from '../utils/helper';
import { APP_DATA, DEFAULT_FORM } from '../common/constants';
export var socket;
const Conference = () => {
    const dispatch = useDispatch();
    const { roomId, role } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const roomState = useHMSStore(selectRoomState);
    const prevState = usePrevious(roomState);
    const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
    const [hideControls, setHideControls] = useState(false);
    const dropdownList = useHMSStore(selectAppData(APP_DATA.dropdownList));
    const dropdownListRef = useRef();
    const [form, setForm] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [prepareTime, setPrepareTime] = useState(0);
    const [answerTime, setAnswerTime] = useState(0);
    const [codeEditorForm, setCodeEditorForm] = useState(DEFAULT_FORM);
    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
    const [hasAnythingChangedInCodeEditor, setHasAnythingChangedInCodeEditor] = useState(false);
    const [screenShareVidRef, setScreenShareVidRef] = useState(null);
    const [displayInput, setDisplayInput] = useState({
        message: '',
        audio: '',
        displayText: '',
        playBeepSound: false,
        codeBlock: '',
    });
    const [popupInputs, setPopupInputs] = useState({
        isDisabled: true,
        questionNumber: 0,
        form: null,
        isNextClick: false,
        idealPopup: false,
        questionType: '',
    });
    const questionNumber = useSelector((state) => state.Interview?.questionNumber);
    const lastQuestionSubmitTimeStampAptitude = useSelector(
        (state) => state.Interview?.lastQuestionSubmitTimeStampAptitude
    );
    const peers = useHMSStore(selectPeers);
    const candidatePeer = peers.filter((peer) => peer.roleName === 'candidate');

    useEffect(() => {
        if (roomId && !socket) {
            // Initialize the socket with reconnection options
            socket = io(generateUrl('socket'), {
                transports: ['websocket'],
                reconnection: true,
                reconnectionAttempts: 3, // Maximum of 3 attempts
                reconnectionDelay: 1000, // Wait 1 second between retries
            });

            socket.on('connect', () => {
                console.log('Socket connected:', socket.id);
                socket.emit('joinRoom', { roomId, name: role });
            });

            socket.on('disconnect', (reason) => {
                console.log(`Socket disconnected due to: ${reason}`);
            });
        }
    }, [roomId, role]);

    const socketDataHandlers = {
        prepareTime: setPrepareTime,
        answerTime: setAnswerTime,
        displayInput: setDisplayInput,
        isPlaying: setIsPlaying,
        popupInputs: setPopupInputs,
        codeEditorForm: setCodeEditorForm,
        changeInCodeEditor: setHasAnythingChangedInCodeEditor,
        screenShareVidRef: setScreenShareVidRef,
    };

    socket?.on('receiveCode', (data) => {
        if (role === 'beam' && socketDataHandlers[data?.type]) socketDataHandlers[data.type](data.value);
    });

    useEffect(() => {
        if (screenShareVidRef && role !== 'beam') {
            dispatch(
                fetchScreenShotImage({
                    image: screenShareVidRef,
                    interviewId: roomId,
                    type: 10,
                    callback: (image) => {
                        if (isValidDataURI(image)) {
                            dispatch(
                                createAndUpdateScreenshots({
                                    roomId,
                                    screenshots: { url: image, type: 'device', faceDetected: true },
                                    mode: 'new',
                                })
                            );
                        }
                    },
                })
            );
        }
    }, [screenShareVidRef]);

    useEffect(() => {
        let timeout = null;
        dropdownListRef.current = dropdownList || [];
        if (dropdownListRef.current.length === 0) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (dropdownListRef.current.length === 0) {
                    setHideControls(true);
                }
            }, 5000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [dropdownList, hideControls]);

    useEffect(() => {
        if (location?.state && role === 'candidate') {
            setForm(location?.state);
            if (!(location?.state?.otpVerified && location?.state?.instructionAccepted)) {
                let meetingURL = `/interview-room/${role}/${roomId}`;
                navigate(meetingURL);
            }
        }
    }, [location?.state, navigate, roomId, role]);

    useEffect(() => {
        if (form?.interviewId && role === 'candidate') dispatch(getQuestionNumber({ interviewId: form?.interviewId }));
    }, [dispatch, form?.interviewId, role]);

    useEffect(() => {
        if (!roomId) {
            navigate(`/`);
            return;
        }
        if (
            !prevState &&
            !(roomState === HMSRoomState.Connecting || roomState === HMSRoomState.Reconnecting || isConnectedToRoom)
        ) {
            navigate(`/preview/${role}/${roomId}`, { state: form });
        }
    }, [isConnectedToRoom, prevState, roomState, navigate, role, roomId, form]);

    const toggleControls = (e) => {
        if (dropdownListRef.current?.length === 0) {
            setHideControls((value) => !value);
        }
    };

    if (!isConnectedToRoom) {
        return <FullPageProgress />;
    }
    return role === 'beam' ? (
        <Flex css={{ size: '100%', height: '100vh', overflow: 'hidden' }} direction="column">
            <Beam
                isPlaying={isPlaying}
                displayInput={displayInput}
                prepareTime={prepareTime}
                answerTime={answerTime}
                popupInputs={popupInputs}
                candidatePeer={candidatePeer}
                codeEditorForm={codeEditorForm}
                hasAnythingChangedInCodeEditor={hasAnythingChangedInCodeEditor}
                setIsSubmitModalOpen={setIsSubmitModalOpen}
                isSubmitModalOpen={isSubmitModalOpen}
                screenShareVidRef={screenShareVidRef}
            />
        </Flex>
    ) : form ? (
        <Flex css={{ size: '100%', height: '100vh', overflow: 'hidden' }} direction="column">
            <MainViewConnector
                form={form}
                socket={socket}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                prepareTime={prepareTime}
                setPrepareTime={setPrepareTime}
                answerTime={answerTime}
                setAnswerTime={setAnswerTime}
                displayInput={displayInput}
                setDisplayInput={setDisplayInput}
                popupInputs={popupInputs}
                setPopupInputs={setPopupInputs}
                questionNumber={questionNumber}
                toggleControls={toggleControls}
                candidatePeer={candidatePeer}
                setIsSubmitModalOpen={setIsSubmitModalOpen}
                isSubmitModalOpen={isSubmitModalOpen}
                lastQuestionSubmitTimeStampAptitude={lastQuestionSubmitTimeStampAptitude}
                screenShareVidRef={screenShareVidRef}
                setScreenShareVidRef={setScreenShareVidRef}
            />
        </Flex>
    ) : (
        <FullPageProgress />
    );
};

export default Conference;
