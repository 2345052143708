// eslint-disable-next-line simple-import-sort/imports
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'regenerator-runtime/runtime';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { selectLocalPeer, useAVToggle, useHMSStore, usePreviewJoin } from '@100mslive/react-sdk';
import { Flex, Loading } from '@100mslive/roomkit-react';
import SidePane from '../../../layouts/SidePane';
import { defaultPreviewPreference, UserPreferencesKeys, useUserPreferences } from '../../hooks/useUserPreferences';
import { CameraMicPermission } from '../../Pages/components/CameraMicPermission';
import { NetworkConnectionLost } from '../../Pages/components/NetworkConnectionLost';
import AudioVideoPrechecks from './components/AudioVideoPrechecks';
import AcknowledgeModal from './components/AcknowledgeModal';

const PreviewJoin = ({
    token,
    onJoin,
    env,
    form,
    skipPreview,
    initialName,
    asRole,
    setIsMount,
    positionTitle,
    companyName,
    isNetworkError,
    permissionsChecks,
    downlinkQuality,
    mediaRecorder,
    startRecording,
    setCheckNetworkError,
    isButtonDisabled,
    isScreenShared,
    setIsScreenShared,
}) => {
    const localPeer = useHMSStore(selectLocalPeer);
    const peerId = localPeer?.id;
    const [, setPreviewPreference] = useUserPreferences(UserPreferencesKeys.PREVIEW, defaultPreviewPreference);
    const [value, setValue] = useState('');
    const [speakingPermission, setSpeakingPermission] = useState(true);
    const [name, setName] = useState('');
    const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
    const [previewError, setPreviewError] = useState(false);
    const [testValidation, setTestValidation] = useState(false);
    const [permissionChecksFailed, setPermissionChecksFailed] = useState(false);
    const [deepGramError, setDeepGramError] = useState(false);
    const [deepGramValue, setDeepGramValue] = useState('');
    const newMediaRecorderRef = useRef(null);
    const currentTextRef = useRef('');
    const socketRef = useRef(null);
    const DG_KEY = process.env.REACT_APP_DEEPGRAM_KEY;
    const videoContainerRef = useRef(null);
    const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const deepGramAudio2text = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true,
                },
            });

            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            await audioContext.resume(); // Ensure context is not suspended

            const source = audioContext.createMediaStreamSource(stream);
            const destination = audioContext.createMediaStreamDestination();

            const biquadFilter = audioContext.createBiquadFilter();
            biquadFilter.type = 'lowpass';
            biquadFilter.frequency.value = 1000;

            source.connect(biquadFilter);
            biquadFilter.connect(destination);

            // Use a supported MIME type
            const options = { mimeType: 'audio/webm;codecs=opus' };
            newMediaRecorderRef.current = new MediaRecorder(destination.stream, options);
            socketRef.current = new WebSocket(`wss://api.deepgram.com/v1/listen?model=base`, ['token', DG_KEY]);

            socketRef.current.onopen = () => {
                newMediaRecorderRef.current.addEventListener('dataavailable', (event) => {
                    if (event.data.size > 0 && socketRef.current?.readyState === WebSocket.OPEN) {
                        socketRef.current.send(event.data);
                    }
                });
            };

            newMediaRecorderRef.current.start(1100);
            console.log('Recording started with noise cancellation');

            socketRef.current.onmessage = (message) => {
                const received = JSON.parse(message.data);
                const transcript = received.channel.alternatives[0]?.transcript;
                if (transcript && received.is_final) {
                    currentTextRef.current += ' ' + transcript;
                    console.log(currentTextRef.current, 'currentText');
                    setDeepGramValue(currentTextRef.current.trim());
                }
            };

            socketRef.current.onclose = () => console.log('Deepgram WebSocket closed');
            socketRef.current.onerror = (err) => console.error('WebSocket error:', err);
        } catch (error) {
            setDeepGramError('Speech could not be recognized.');
            console.error('Error accessing microphone:', error);
        }
    };

    useEffect(() => {
        if (!permissionsChecks) {
            const checkAndUpdateState = () => {
                setPermissionChecksFailed(true);
            };
            const timeoutId = setTimeout(() => {
                checkAndUpdateState();
            }, 30000);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [permissionsChecks]);

    useEffect(() => {
        if (!name) setName(initialName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialName]);

    const startListening = () => {
        currentTextRef.current = ''; // clear previous data
        SpeechRecognition.startListening({ continuous: true });
        deepGramAudio2text();
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
        if (newMediaRecorderRef.current && newMediaRecorderRef.current.state !== 'inactive') {
            newMediaRecorderRef.current.stop();
            if (newMediaRecorderRef.current?.stream) {
                newMediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
            }
            newMediaRecorderRef.current = null;
        }
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.close();
            console.log('✅ DeepGram WebSocket closed manually.');
        }
        socketRef.current = null;
    };

    useEffect(() => {
        setValue(transcript || deepGramValue);
    }, [transcript, deepGramValue]);

    useEffect(() => {
        return () => {
            stopListening(); // Clean up WebSocket + recorder if user navigates away
        };
    }, []);

    useEffect(() => {
        if (!browserSupportsSpeechRecognition && deepGramError) {
            console.error('Speech recognition is not supported in this browser or on this device.');
            // Handle the lack of support, disable speech-related features, or show a message to the user.
            setSpeakingPermission(false);
        }
    }, [browserSupportsSpeechRecognition, deepGramError]);

    const { preview, join } = usePreviewJoin({
        name,
        token,
        initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
        initialSettings: {
            isAudioMuted: skipPreview || false,
            isVideoMuted: skipPreview || false,
            speakerAutoSelectionBlacklist: ['Yeti Stereo Microphone'],
        },
        captureNetworkQualityInPreview: true,
        handleError: (_, method) => {
            if (method === 'preview') {
                setPreviewError(true);
            }
        },
        asRole,
    });
    useEffect(() => {
        if (testValidation) setIsMount(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testValidation]);

    const savePreferenceAndJoin = useCallback(() => {
        stopListening();
        setPreviewPreference({
            name,
            isAudioMuted: !isLocalAudioEnabled,
            isVideoMuted: !isLocalVideoEnabled,
        });
        join();
        onJoin && onJoin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [join, isLocalAudioEnabled, isLocalVideoEnabled, name, setPreviewPreference, onJoin]);

    useEffect(() => {
        if (token) {
            if (skipPreview) savePreferenceAndJoin();
            else preview();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, skipPreview]);

    return isNetworkError ? (
        <NetworkConnectionLost />
    ) : testValidation ? (
        <AcknowledgeModal
            onJoin={savePreferenceAndJoin}
            name={name}
            form={form}
            token={token}
            previewError={previewError}
            value={value}
            speakingPermission={speakingPermission}
            positionTitle={positionTitle}
            companyName={companyName}
            videoContainerRef={videoContainerRef}
            recorder={mediaRecorder}
            isButtonDisabled={isButtonDisabled}
        />
    ) : (
        <Flex
            css={{
                flex: '1 1 0',
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                overflowY: 'auto',
                width: '100%',
                '@md': {
                    height: '100%',
                },
            }}
            justify="center"
            align="center"
        >
            {token ? (
                permissionChecksFailed ? (
                    <CameraMicPermission />
                ) : (
                    <AudioVideoPrechecks
                        peerId={peerId}
                        isLocalAudioEnabled={isLocalAudioEnabled}
                        isLocalVideoEnabled={isLocalVideoEnabled}
                        downlinkQuality={downlinkQuality}
                        startRecording={startRecording}
                        startListening={startListening}
                        setTestValidation={setTestValidation}
                        setCheckNetworkError={setCheckNetworkError}
                        isScreenShared={isScreenShared}
                        setIsScreenShared={setIsScreenShared}
                    />
                )
            ) : (
                <Loading size={100} />
            )}
            <SidePane
                css={{
                    position: 'unset',
                    mr: '$10',
                    '@lg': { position: 'fixed', mr: '$0' },
                }}
            />
        </Flex>
    );
};

export default PreviewJoin;
